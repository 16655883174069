import me from './pictures/me.jpg'

const Home = () => {
    return ( 


<div className="Home">
<br/><br/><br/><br/>
 

    <a1>Welcome to the world of Shumail!</a1>
    <br/><br/>
    
    <img src={me} alt="A" />

    

    <br/><br/><br/>
    <a2 className="bodyhome">
    This is currently what I look like, though it really depends on my hair. It's generally in 3 stages, 
    short and maintained, in the growing out process, or it has been grown out and being maintained. 
    <br/><br/>

    For this website, I wanted the theme to be like one of those random websites you find when you were 
    in highschool looking for a "reliable" source for your history papers and you came across a random website
    that had a very simple heading and random colour background. Or one of those random calculus websites that 
    explained everything really well.

    <br/><br/>
    I also thought, if this website was a t-shirt, I'd want to wear those colours. Enjoy!! 

    <br/><br/>

    My current email: zaidi07@ufl.edu 

    </a2>

    

 <br/><br/> <br/><br/> <br/><br/> <br/><br/> <br/><br/><br/> <br/>
 <br/> <br/>

    

    



</div>

     );
}
 
export default Home ;
